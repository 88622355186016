@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Popup {
    padding: 0;

    .ContentContainer {
        margin: 0 auto;
        padding: 30px 20px;

        @include media_tablet {
            padding: 40px 62px;
        }

        @include media_desktop {
            padding: px-to-vh(60px) px-to-vh(119px);
        }

        .Title {
            margin: 0 0 20px 0;
            word-break: break-word;
            text-align: center;

            @include media_tablet {
                letter-spacing: -2px;
            }

            @include media_desktop {
                margin: 0 0 30px 0;
                width: auto;
            }
        }

        .StepList {
            word-break: break-word;

            p {
                text-align: center;
                margin: 10px 0 0;
            }
        }
    }
}

.PopupContainerClassName {
    @include media_tablet {
        max-width: 624px;
    }

    @include media_desktop {
        max-width: px-to-vh(965px);
    }
}

.ImageContainer {
    width: 100%;
    height: 281px;

    @include media_tablet {
        height: 430px;
    }

    @include media_desktop {
        height: px-to-vh(423px);
    }

    .Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
