.PopupContainer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
}

.Overlay {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}
