@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.InputWrapper {
    margin: 30px 0 0;

    position: relative;
    height: px-to-vw(46px);

    @include media_tablet {
        height: px-to-vh(46px);
        width: px-to-vh(450px);
        margin: 0 auto 10px;
    }

    .DatePicker {
        display: block;
        height: 100%;
        width: 100%;
        border: solid 1px var(--color-alto-second);
        border-radius: 3px;
        font-size: 14px;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        padding: 13px 16px 13px 14px;

        @include media_tablet {
            font-size: var(--font-size__3-14);
        }

        &:focus {
            outline: none;
            border: 2px solid black;

            ~ .InputLabel {
                top: 0;
            }
        }
    }

    .EmptyDatePicker {
        border: 1px solid red;
    }

    .InputLabel {
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 10px;
        padding: 0 5px;
        background: var(--color-white);
        transition: 0.2s ease all;
        top: 50%;
        transform: translateY(-50%);
        font-size: var(--font-size__1-14);

        @include media_tablet {
            font-size: var(--font-size__3-14);
        }
    }

    .DatePicker:not(:placeholder-shown) ~ .InputLabel {
        top: 0;
    }

    .IconContainer {
        position: absolute;
        width: 46px;
        height: 100%;
        top: 0;
        right: 0;
        transform: scaleX(-1);
        cursor: pointer;

        .PurchaseDateIcon {
            width: 24px;
            height: 100%;
            margin: 0 auto;
            display: block;
        }
    }

    .IncorrectDate {
        margin: 0;
        color: red;
        font-size: var(--font-size__1-12);

        @include media_tablet {
            margin-left: 10px;
            font-size: var(--font-size__1-14);
        }
    }
}
