@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.IconFrontWrap {
    overflow: hidden;
    float: left;
    width: 100%;
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.IconFront {
    float: left;
    width: 100%;
    height: 1.125em;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ArrowContainer {
    position: relative;
    float: left;
    margin-left: 12px;
}

.Arrow {
    transition: 2s ease;
}

.IconFront svg {
    display: block;
    fill: var(--color-button-arrow);
    height: 100%;
    max-width: 100px;
}

.IconBackWrap {
    transform: translate3d(-103%, 0, 0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.IconBack {
    transform: translate3d(103%, 0, 0);
    float: left;
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    width: 100%;
}

.IconBack svg {
    display: block;
    fill: var(--color-white);
    height: 100%;
    max-width: 100px;
}

:global(.button-arrow__animation):hover .IconFrontWrap {
    transform: translate3d(103%, 0, 0);
}

:global(.button-arrow__animation):hover .IconFront {
    transform: translate3d(-103%, 0, 0);
}

:global(.button-arrow__animation):hover .IconBackWrap {
    transform: translateZ(0);
}

:global(.button-arrow__animation):hover .IconBack {
    transform: translateZ(0);
}
