.NotificationText {
    text-decoration-color: var(--color-rodeo-dust);
    color: var(--color-dark-link);
    text-decoration: underline;
    text-transform: inherit;
    line-height: inherit;
    white-space: inherit;
    margin: inherit;
    padding: inherit;
    text-align: inherit;
    font: inherit;
    letter-spacing: inherit;
    height: auto;
    justify-content: inherit;
}
