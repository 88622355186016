#root {
    --square-elements-scale: 1;

    &.ica-font-medium {
        --square-elements-scale: 1.35;
    }

    &.ica-font-big {
        --square-elements-scale: 1.5;
    }
}
