#root {
    --color-abbey: #575960;
    --color-alabaster-lighten-a50: rgba(247, 247, 247, 0.5);
    --color-alabaster-lighten: #f7f7f7;
    --color-alabaster: #f9f9f9;
    --color-alto-lighten: #d2d2d2;
    --color-alto-second: #d3d3d3;
    --color-alto: #d9d9d9;
    --color-athens-gray: #f8f8f9;
    --color-atlantis-lighten: #a0ca32;
    --color-atlantis: #a0ca32;
    --color-black-a10: rgba(0, 0, 0, 0.1);
    --color-black-a30: rgba(0, 0, 0, 0.3);
    --color-black: black;
    --color-bombay: #afb0b4;
    --color-bunker: #0e1416;
    --color-charade-a20: rgba(39, 42, 51, 0.2);
    --color-charade-a60: rgba(39, 42, 51, 0.6);
    --color-charade: #272a33;
    --color-cararra: #f5f4f2;
    --color-limeade: #618508;
    --color-cinnabar: #dc3838;
    --color-cod-gray: #111111;
    --color-corn: #e5c100;
    --color-dim-gray: #616161;
    --color-dove-gray-a30: rgba(102, 102, 102, 0.3);
    --color-dove-gray: #666666;
    --color-dusty-gray: #979797;
    --color-gainsboro: #e0e0e0;
    --color-gallery: #eaeaea;
    --color-hint-of-red: #f3ede8;
    --color-iron: #e2e5e6;
    --color-guardsman-red: #cf0000;
    --color-mariner: #306fd6;
    --color-mercury: #e8e8e8;
    --color-nepal: #98adba;
    --color-nobel: #9e9e9e;
    --color-oslo-gray: #898a8c;
    --color-paarl: #9d6b25;
    --color-pearl-bush-lighten: #f0e9e5;
    --color-pearl-bush: #efe9e3;
    --color-revolver-a95: rgba(56, 56, 57, 0.95);
    --color-robins-egg-blue: #0cc;
    --color-rodeo-dust: #cfb2a0;
    --color-shark: #2d3038;
    --color-storm-gray: #73757c;
    --color-sunglow: #fc3;
    --color-swiss-cofee: #ded8d6;
    --color-transparent: transparent;
    --color-tuna: #383839;
    --color-tundora: #444444;
    --color-white-a50: rgba(255, 255, 255, 0.5);
    --color-white-a60: rgba(255, 255, 255, 0.6);
    --color-white-smoke: #f5f5f5;
    --color-white: white;
    --color-wild-sand: #f4f4f4;
    --color-silver-chalice: #a1a1a1;

    --color-activation-page-background: var(--color-hint-of-red);
    --color-app-intro-page-divider-text: var(--color-tuna);
    --color-back-button-text: var(--color-tuna);
    --color-background-devices-list: var(--color-pearl-bush);
    --color-battery-count-text: var(--color-charade);
    --color-benefit-description-text: var(--color-storm-gray);
    --color-bright-confirm-mark: var(--color-white);
    --color-bright-link: var(--color-rodeo-dust);
    --color-bright-secondary-text: var(--color-white);
    --color-bright-text: var(--color-white);
    --color-button-arrow: var(--color-tuna);
    --color-button-border: var(--color-white-a50);
    --color-button-dark-border: var(--color-shark);
    --color-button-dark-text: var(--color-white);
    --color-button-dark: var(--color-rodeo-dust);
    --color-button-text: var(--color-white-a50);
    --color-button-white-background: var(--color-white-a60);
    --color-button-white-text: var(--color-nepal);
    --color-carousel-dots: var(--color-black);
    --color-common-border: var(--color-transparent);
    --color-dark-link: var(--color-rodeo-dust);
    --color-dark-text: var(--color-black);
    --color-device-info-block-expand-arrow: var(--color-charade-a60);
    --color-device-info-container-border: var(--color-mercury);
    --color-device-list-arrow: var(--color-black-a10);
    --color-device-list-svg-arrow-fill: var(--color-tuna);
    --color-device-settings-button-state-text: var(--color-storm-gray);
    --color-grey-text: var(--color-dove-gray);
    --color-guides-background: var(--color-pearl-bush-lighten);
    --color-guides-slider-item-arrow: var(--color-alabaster-lighten);
    --color-guides-slider-item-background: var(--color-alabaster-lighten-a50);
    --color-h2-dark-text: var(--color-tuna);
    --color-intro-page-header: var(--color-tuna);
    --color-last-btn-next-word-text: var(--color-dim-gray);
    --color-locked-text: var(--color-guardsman-red);
    --color-not-compatible-browser-link: var(--color-dim-gray);
    --color-not-compatible-page-background: var(--color-athens-gray);
    --color-pairing-guide-background: var(--color-pearl-bush-lighten);
    --color-pairing-skip-popup-background: var(--color-tuna);
    --color-pairing-skip-svg-arrow-fill: var(--color-rodeo-dust);
    --color-popup-background: var(--color-white);
    --color-prefecence-center-background: var(--color-alabaster-lighten);
    --color-txt-regular-text: var(--color-tuna);

    &.ica-high-contrast {
        --color-activation-page-background: var(--color-bunker);
        --color-app-intro-page-divider-text: var(--color-white);
        --color-back-button-text: var(--color-robins-egg-blue);
        --color-background-device-settings: var(--color-cod-gray);
        --color-background-devices-list: var(--color-cod-gray);
        --color-battery-count-text: var(--color-robins-egg-blue);
        --color-benefit-description-text: var(--color-sunglow);
        --color-bright-confirm-mark: var(--color-white);
        --color-bright-link: var(--color-robins-egg-blue);
        --color-bright-secondary-text: var(--color-sunglow);
        --color-bright-text: var(--color-sunglow);
        --color-button-arrow: var(--color-robins-egg-blue);
        --color-button-border: var(--color-white);
        --color-button-dark-border: var(--color-robins-egg-blue);
        --color-button-dark-text: var(--color-robins-egg-blue);
        --color-button-dark: var(--color-robins-egg-blue);
        --color-button-text: var(--color-robins-egg-blue);
        --color-button-white-background: var(--color-white);
        --color-button-white-text: var(--color-robins-egg-blue);
        --color-button-white-сustom-text: var(--color-robins-egg-blue);
        --color-carousel-dots: var(--color-robins-egg-blue);
        --color-common-border: var(--color-bombay);
        --color-dark-background: var(--color-cod-gray);
        --color-dark-link: var(--color-robins-egg-blue);
        --color-dark-text: var(--color-sunglow);
        --color-device-info-block-expand-arrow: var(--color-robins-egg-blue);
        --color-device-info-container-border: var(--color-robins-egg-blue);
        --color-device-list-arrow: var(--color-robins-egg-blue);
        --color-device-list-svg-arrow-fill: var(--color-robins-egg-blue);
        --color-device-settings-button-state-text: var(--color-corn);
        --color-grey-text: var(--color-sunglow);
        --color-guides-background: var(--color-cod-gray);
        --color-guides-slider-item-arrow: var(--color-robins-egg-blue);
        --color-guides-slider-item-background: var(--color-bunker);
        --color-h2-dark-text: var(--color-robins-egg-blue);
        --color-intro-page-header: var(--color-sunglow);
        --color-last-btn-next-word-text: var(--color-robins-egg-blue);
        --color-locked-text: var(--color-robins-egg-blue);
        --color-not-compatible-browser-link: var(--color-sunglow);
        --color-not-compatible-icon-filter: var(--filter-invert);
        --color-not-compatible-page-background: var(--color-cod-gray);
        --color-pairing-guide-background: var(--color-cod-gray);
        --color-pairing-skip-popup-background: var(--color-cod-gray);
        --color-pairing-skip-svg-arrow-fill: var(--color-robins-egg-blue);
        --color-popup-background: var(--color-cod-gray);
        --color-prefecence-center-background: var(--color-cod-gray);
        --color-txt-regular-text: var(--color-sunglow);
        --filter-back-button: var(--filter-invert);
        --filter-device-mode-button: var(--filter-invert);
        --filter-intro-page-bluetooth-icon: var(--filter-invert);
        --filter-invert: invert(1);
        --filter-video: invert(1);
        --stroke-tnc-icon: var(--color-sunglow);
    }

    &.ica-greyscale {
        --color-background-devices-list: var(--color-iron);
        --color-battery-count-text: var(--color-dim-gray);
        --color-bright-link: var(--color-white);
        --color-bright-text: var(--color-tundora);
        --color-button-arrow: var(--color-tundora);
        --color-dark-link: var(--color-black);
        --color-dark-text: var(--color-tundora);
        --color-device-list-svg-arrow-fill: var(--color-black-a30);
        --color-guides-background: var(--color-white);
        --color-h2-dark-text: var(--color-tundora);
        --color-last-btn-next-word-text: var(--color-white);
        --color-locked-text: var(--color-tundora);
        --color-not-compatible-icon-filter: var(--filter-grayscale);
        --color-not-compatible-page-background: var(--color-athens-gray);
        --color-pairing-guide-background: var(--color-gallery);
        --color-txt-regular-text: var(--color-tundora);
        --filter-back-button: var(--filter-grayscale);
        --filter-device-mode-button: var(--filter-invert);
        --filter-grayscale: grayscale(1);
        --filter-intro-page-bluetooth-icon: var(--filter-grayscale);
        --filter-video: grayscale(1);
    }
}
