@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-tuna);
    min-height: 100%;
    padding: 60px 20px;
    width: 100%;

    @include media_tablet {
        padding: 63px 134px;
    }

    @include media_desktop {
        padding: 8.15vh 2.06vh;
    }
}

.Container {
    @include media_tablet {
        width: 100%;
        min-width: unset;
    }
}

.Container {
    @include media_tablet {
        width: 100%;
        min-width: unset;
    }
}

.Image {
    opacity: 0.4;
    width: 48px;
    height: 63px;

    path {
        stroke: var(--stroke-tnc-icon);
    }

    @include media_tablet {
        width: 63px;
        height: 82px;
    }

    @include media_desktop {
        width: 7.78vh;
        height: 10.09vh;
    }
}

.Header {
    display: flex;
    justify-content: center;
}

.Title.Title {
    color: var(--color-bright-secondary-text);
    margin: 30px 0 20px;
    text-align: center;

    @include media_tablet {
        margin: 31px 0 30px;
    }

    @include media_desktop {
        line-height: 1.28;
        letter-spacing: -2px;
        margin: 30px 0 20px;
    }
}

.Description {
    white-space: pre-line;
    color: var(--color-bright-secondary-text) !important;
    text-align: center;
    font-size: var(--font-size__4-2_67);

    @include media_tablet {
        font-size: var(--font-size__1-16);
        line-height: 28px;
        margin: 0 auto;
    }

    @include media_desktop {
        width: 60%;
        font-size: var(--font-size__5-1_85);
        margin: 4.12vh auto;
        line-height: 1.8;
    }
}

.ButtonAgree,
.ButtonDecline {
    font-size: var(--font-size__4-2_93);
    line-height: 1;
    display: block;
    min-width: 200px;
    padding: 15px 20px;
    color: var(--color-button-text);

    @include media_tablet {
        min-width: 210px;
        font-size: var(--font-size__1-12);
        margin: 0;
        white-space: nowrap;
    }

    @include media_desktop {
        min-width: 230px;
        font-size: var(--font-size__5-1_65);
    }

    @include media_desktop_4k {
        min-width: 460px;
        height: 4.4vh;
        padding: 25px 45px;
    }
}

.PreferenceCenter {
    color: var(--color-bright-secondary-text);
}

.PreferenceCenter {
    font-size: var(--font-size__4-2_93);

    @include media_tablet {
        font-size: var(--font-size__1-12);
    }

    @include media_desktop {
        font-size: var(--font-size__5-1_65);
    }
}

.ButtonAgree {
    background-color: var(--color-black-a30);
    margin: 15px;

    @include media_tablet {
        margin-right: 0;
    }
}

.Footer {
    margin-top: 30px;
}

.ButtonBlock {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px;

    @include media_desktop {
        padding: 0;
        margin-bottom: 1.94vh;
    }
}

@include media_desktop {
    .Footer {
        margin: 0 auto;
    }
}
