@import '../scss/medias';

.ica {
    ul {
        list-style: none;
        padding-inline-start: 0;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    * {
        box-sizing: border-box;
    }

    *:focus {
        outline: none;
    }

    *:focus-visible {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    &.touch {
        *:focus {
            outline: none;
        }
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        -webkit-appearance: none !important;
    }
}

#root {
    min-height: 568px;

    @include media_desktop {
        min-height: 100%;
    }

    &.ica-high-contrast {
        .dynamic-bg {
            background-color: var(--color-cod-gray) !important;
        }
    }
    &.ica-greyscale {
        .dynamic-bg {
            background-color: var(--color-iron) !important;
        }
    }
}
