@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.Button {
    height: auto;
    left: 16px;
    margin: 0;
    position: absolute;
    top: 15px;
    width: auto;
    padding: 0;
    color: var(--color-back-button-text);

    @include media_tablet {
        left: 0;
        top: 20px;
    }

    @include media_desktop {
        top: px-to-vh(38px);
    }

    span {
        display: none;

        @include media_tablet {
            display: inline;
            font-size: var(--font-size__1-12);
            margin-left: 11px;
            margin-top: 1px;
        }

        @include media_desktop {
            font-size: var(--font-size__5-1_48);
            margin-left: 12px;
        }
    }

    svg {
        filter: var(--filter-back-button);

        @include media_desktop_4k {
            width: 69px;
            height: auto;
        }
    }
}
