@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Arrow.Arrow {
    background-color: var(--color-white);
    cursor: pointer;
    display: none;
    height: 3.7vh;
    left: 1vh;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3.7vh;
    z-index: 1;
    border-radius: 100%;

    @include media_desktop {
        width: 5.5vh;
        height: 5.5vh;
        left: 12vh;
    }

    @include media_tablet {
        display: block;
    }

    &:hover {
        background-color: var(--color-tuna);

        &:before {
            border-color: var(--color-white);
        }
    }

    &:focus-visible {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    &:global(.slick-disabled) {
        cursor: default;
        &:hover {
            background-color: var(--color-white);

            &:before {
                border-color: var(--color-dusty-gray);
            }
        }

        &:before {
            border-color: var(--color-dusty-gray);
        }
    }

    &:focus {
        outline: none !important;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-25%, -50%) rotate(-45deg);
        transform-origin: center;
        border: 1px solid var(--color-back-button-text);
        border-right: none;
        border-bottom: none;
        width: px-to-vh(15px);
        height: px-to-vh(15px);
        background-image: none;
    }
}

.RightArrow.RightArrow {
    &::before {
        transform: translate(-75%, -50%) rotate(135deg);
    }
}

.RightArrow.RightArrow {
    left: unset;
    right: 1vh;

    @include media_desktop {
        right: 12vh;
    }
}

.IsTabletArrowNotVisible.IsTabletArrowNotVisible {
    @include media_tablet {
        display: none;
    }

    @include media_desktop {
        display: block;
    }
}
