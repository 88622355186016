@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Container {
    display: flex;
    flex-direction: column;
    font-family: var(--font-light);
    padding: 40px 20px 30px;
    height: 100vh;
    background: var(--color-white);

    @include media_tablet {
        background-color: var(--color-alabaster-lighten);
        padding: 0;
    }

    .Title {
        font-family: var(--font-nbw);
        line-height: 36px;
        text-align: center;
        letter-spacing: 0;
        margin: 0;

        @include media_tablet {
            margin-top: px-to-vh(80px);
            line-height: px-to-vh(64px);
        }
    }

    .Description {
        text-align: center;
        font-size: var(--font-size__1-14);
        margin: 10px 0 0;

        @include media_tablet {
            font-size: var(--font-size__3-20);
            margin-top: px-to-vh(40px);
        }
    }

    .InputContainer {
        background-color: var(--color-white);
        margin: 0 auto;
        width: 100%;
        height: 100%;

        @include media_tablet {
            margin-top: px-to-vh(40px);
            width: px-to-vh(570px);
            height: max-content;
            padding: 45px 60px 45px;
        }

        .InputTitle {
            font-size: var(--font-size__1-14);
            margin: 30px 0 10px;

            @include media_tablet {
                font-size: var(--font-size__3-14);
                margin: 0 0 10px;
            }
        }

        .ButtonContiner {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 5%;
            height: max-content;

            .ContinueBtn {
                right: 0;
                left: 0;
            }

            .DoItLaterBtn {
                margin: 10px auto 0;

                @include media_tablet {
                    margin: 30px auto 0;
                }
            }
        }

        .ButtonContinerDesktop {
            position: relative;
            bottom: 0;
            margin-top: px-to-vh(40px);
        }
    }

    .Calendar {
        box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.5);
        background-color: rgb(255, 253, 251) !important;
        border-radius: 4px !important;
        border: 1px solid rgb(246, 244, 240) !important;
    }
}

:global(.react-datepicker__header) {
    background-color: rgb(255, 253, 251);
}

:global(.react-datepicker__year-select) {
    border: none;

    @include media_tablet {
        border: revert;
    }
}

:global(.react-datepicker__month-select) {
    border: none;

    @include media_tablet {
        border: revert;
    }
}

:global(.react-datepicker__day-names) {
    margin-top: 16px;
    border-top: 1px solid rgb(211, 211, 211) !important;
}

:global(.react-datepicker__triangle:before) {
    border-bottom-color: rgb(255, 253, 251) !important;
}
