@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.CommonContainer {
    .TextContainer {
        width: 315px;
        margin: 60px auto 0;

        @include media_tablet {
            width: px-to-vw-tablet(624px);
            margin: px-to-vh-tablet(140px) auto 0;
        }

        @include media_desktop {
            width: px-to-vh(1170px);
            margin: px-to-vh(151px) auto 0;
        }

        .Title {
            margin: 0;
        }

        .Description {
            margin: 16px auto 0;
            text-align: center;
            line-height: 26px;
            font-size: var(--font-size__1-14);

            @include media_tablet {
                font-size: var(--font-size__1-16);
                width: px-to-vw-tablet(500px);
                margin: px-to-vh-tablet(20px) auto px-to-vh-tablet(30px);
            }

            @include media_desktop {
                font-size: var(--font-size__3-20);
                width: px-to-vh-tablet(900px);
                margin: px-to-vh(40px) auto px-to-vh(40px);
                line-height: 36px;
                letter-spacing: 0;
            }

            @include media_desktop_4k {
                line-height: 56px;
            }
        }
    }

    .ContinueButton {
        position: fixed;
        bottom: 30px;
        right: 0;
        left: 0;

        @include media_tablet {
            position: relative;
            bottom: auto;
        }
    }
}
