@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-revolver-a95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    width: 100%;
}

.Title.Title,
.Text.Text {
    color: var(--color-bright-secondary-text);
}

.Title {
    font-size: var(--font-size__4-6_4);
    line-height: 1.33;
    text-align: center;
    margin: 40px 0 20px;
    font-weight: normal;
    font-style: normal;

    @include media_tablet {
        font-size: var(--font-size__1-32);
    }

    @include media_desktop {
        font-size: var(--font-size__3-50);
    }
}

.Text {
    font-size: 3.73333vw;
    line-height: 1.86;
    text-align: center;
    padding: 0 80px;

    @include media_tablet {
        font-size: var(--font-size__1-16);
    }

    @include media_desktop {
        font-size: var(--font-size__3-20);
    }
}

.PulseContainer {
    width: px-to-vw(192px);
    height: px-to-vw(192px);
    margin: 0 auto 0;
    position: relative;
    filter: var(--filter-grayscale);

    @include media_tablet {
        width: px-to-vh-tablet(192px);
        height: px-to-vh-tablet(192px);
    }

    @include media_desktop {
        width: px-to-vh(288px);
        height: px-to-vh(288px);
    }
}

.PulseCircle {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50%;
        transform: scale(1);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        opacity: 0.7;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50%;
    }
}

.Circle1 {
    width: 100%;
    height: 100%;
    background-color: var(--color-pearl-bush);

    &:before {
        background-color: rgba(239, 233, 227, 0.7);
    }

    &:after {
        background-color: var(--color-pearl-bush);
    }
}

.Circle2 {
    width: 73%;
    height: 73%;

    &:before {
        background-color: rgba(243, 237, 232, 0.7);
    }
    &:after {
        background-color: var(--color-hint-of-red);
        background-position: center center;
        background-repeat: no-repeat;
    }

    .Logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 28.6%;
        height: 28.6%;
    }
}

.pulse-button:hover {
    animation: none;
}

@keyframes pulse {
    to {
        transform: scale(1.25);
        opacity: 0;
    }
}
