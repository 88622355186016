@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.PopupContainer {
    background-color: var(--color-pairing-skip-popup-background);
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    max-width: 100%;
}

.Popup {
    padding: 50px 30px;

    @include media_tablet {
        padding-top: 63px;
    }

    @include media_desktop {
        padding-top: 90px;
        padding-bottom: 90px;
    }
}

.Container {
    @include media_tablet {
        max-width: 71%;
    }

    @include media_desktop {
        min-width: 400px;
        max-width: 35%;
    }
}

.Header {
    display: flex;
    justify-content: center;
}

.Title.Title {
    color: var(--color-button-dark-text);
    margin-bottom: 20px;
    text-align: center;

    @include media_tablet {
        width: 55%;
        margin: 0 auto 25px;
        letter-spacing: -2px;
    }

    @include media_desktop {
        margin: 0 0 40px;
        width: auto;
    }
}

.Description.Description {
    color: var(--color-bright-secondary-text);
    text-align: center;

    @include media_tablet {
        width: 90%;
        margin: 0 auto 20px;
    }

    @include media_desktop {
        margin-bottom: 50px;
        line-height: 1.8;
        width: 100%;
    }

    span {
        color: var(--color-rodeo-dust);
        font-family: var(--font-bold);
        cursor: pointer;
        text-decoration: underline;
    }
}

.ButtonDecline {
    color: var(--color-button-dark-text);

    @include media_desktop {
        margin-top: 20px;
    }
}

.ButtonAgree {
    color: var(--color-button-text);
    background-color: var(--color-black-a30);

    svg {
        fill: var(--color-pairing-skip-svg-arrow-fill);
    }
}

.ButtonDecline,
.ButtonAgree {
    @include media_desktop_4k {
        height: 4.4vh;
    }
}

.Footer {
    margin-top: 30px;
}
