@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.NotificationContainer {
    width: 90%;
    height: auto;
    border-radius: 3px;
    position: relative;
    margin-top: 10px;
    padding: 15px 24px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 10;
    -webkit-tap-highlight-color: transparent;
    background-color: var(--color-popup-background);

    @include media_tablet {
        max-width: 500px;
        width: 85%;
        padding: 22px 25px;
        margin-top: 0;
    }

    @include media_desktop {
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 700px;
        width: 100%;
        padding: 20px;
        box-shadow: 0 2px 8px 0 var(--color-black-a30);
        border-radius: 0;
    }

    @include media_desktop_4k {
        max-width: 1000px;
        padding: 40px;
    }
}

.SuccessfulMessageIcon,
.FailMessageIcon,
.InfoMessageIcon {
    box-sizing: content-box;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    align-self: flex-start;
    margin: 0.9vh 1.4vh 0.45vh 0;

    @include media_tablet {
        width: 25px;
        height: 25px;
    }

    @include media_desktop {
        width: 2.8vh;
        height: 2.8vh;
    }
}

.Text {
    margin: 0;
    text-align: left;
    width: 80%;
    word-break: break-word;

    @include media_desktop {
        line-height: normal;
    }

    @include media_desktop_4k {
        margin-left: 20px;
    }
}

.IconCrossBlack {
    display: block;
    position: relative;
    right: 3%;
    top: 0;
    margin-left: 15%;
    width: 20px;
    height: 20px;
    align-self: start;
    box-sizing: content-box;
    border: 0.9vh solid transparent;

    @include media_tablet {
        width: 50px;
        height: 25px;
    }

    @include media_desktop {
        height: 2.8vh;
        width: 2.8vh;
    }

    &:before,
    &:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 2px;
        height: 15px;

        @include media_desktop {
            height: 2.8vh;
        }
    }
}

.CloseAnimation {
    animation-name: notificationAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes notificationAnimation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        height: 0;
    }
}
