@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.FloatButton {
    width: auto;
    position: absolute;
    right: 16px;
    top: 15px;
    margin: 0;
    height: auto;
    text-align: right;
    cursor: pointer;
    z-index: 10;
    padding: 0;
    font-size: var(--font-size__1-10);
    color: var(--color-back-button-text);

    @include media_tablet {
        font-size: var(--font-size__1-12);
        top: 19px;
        right: 0;
    }

    @include media_desktop {
        font-size: var(--font-size__5-1_48);
        top: px-to-vh(38px);
        right: 0;
    }
}

.IsLeft {
    right: unset;
    left: 16px;

    @include media_tablet {
        left: 0;
    }
}
