@import '../../../../../assets/scss/medias';

.HeaderIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @include media_tablet {
        margin-bottom: 18px;
    }

    @include media_desktop {
        position: absolute;
        left: 50%;
        top: 40px;
        transform: translateX(-50%);
        margin: 0;
    }

    @include media_desktop_4k {
        position: static;
        transform: none;
    }

    svg {
        width: 20px;
        height: auto;

        @include media_tablet {
            width: 30px;
        }

        @include media_desktop_4k {
            width: 80px;
        }
    }
}
