@import '../../../../../../assets/scss/medias';

.Text.Text {
    margin-left: 10px;
    color: var(--color-bright-secondary-text);

    @include media_tablet {
        margin-top: 10px;
    }

    @include media_desktop {
        margin: 0;
    }
}

.Image {
    width: 100%;
    height: auto;
}

.Container {
    display: flex;
    align-items: center;

    @include media_tablet {
        width: 79%;
        margin: 0 auto;
    }

    @include media_desktop {
        margin-bottom: 30px;
        width: auto;
    }

    @include media_desktop_4k {
        &:last-child {
            margin-bottom: 4.6vh;
        }
    }
}

.ImageContainer {
    flex-basis: 30px;
    margin-bottom: auto;
    margin-top: auto;
    flex-grow: 0;
    flex-shrink: 0;

    @include media_tablet {
        height: 38px;
        flex-basis: 38px;
    }

    @include media_desktop {
        height: 4.6vh;
        flex-basis: 4.6vh;
        margin: 0 20px 0 0;
    }
}
