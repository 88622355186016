@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Popup {
    .Title {
        margin: 0 0 20px 0;
        word-break: break-word;
        text-align: center;

        @include media_tablet {
            letter-spacing: -2px;
        }

        @include media_desktop {
            margin: 0 0 30px 0;
            width: auto;
        }
    }

    .Description {
        text-align: center;
        font-size: var(--font-size__1-16);
        color: var(--color-txt-regular-text);
        white-space: pre-line;
    }

    .RegisterBtn {
        margin-top: 30px;
        color: var(--color-dark-text);
    }

    .CancelBtn {
        margin: 0 auto;
        color: var(--color-dark-text);
    }

    .ButtonSecondary {
        margin-top: 20px;
        margin-bottom: 0;
    }
}
