@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Page {
    background-color: var(--color-tuna);
    padding: 60px 27px 28px 20px;
    width: 100%;
    min-height: 100%;

    @include media_tablet {
        padding-top: 63px;
    }

    @include media_desktop {
        padding: 8.15vh 27px 28px 20px;
    }
}

.Header {
    display: flex;
    justify-content: center;
}

.Title.Title {
    color: var(--color-bright-secondary-text);
    margin: 30px 0 0;
    text-align: center;

    @include media_tablet {
        margin: 40px 0 23px;
    }

    @include media_desktop {
        font-size: var(--font-size__5-4_63);
        line-height: 1.28;
        letter-spacing: -2px;
        margin: 40px 0 0;
    }
}

.LineTitle {
    position: relative;

    @include media_tablet {
        margin-bottom: 60px;
    }

    @include media_desktop {
        margin-bottom: 80px;
    }

    &:after {
        height: 1px;
        position: absolute;
        bottom: -29px;
        left: 0;
        background-color: var(--color-rodeo-dust);

        @include media_tablet {
            content: '';
            width: 60px;
        }

        @include media_desktop {
            width: 135px;
        }
    }
}

.TermsOfUsePageDescription {
    color: var(--color-bright-secondary-text) !important;
    text-align: center;
    margin-top: 16px;

    @include media_tablet {
        width: 80%;
        margin: 16px auto 0;
    }

    @include media_desktop {
        width: 60%;
        margin: 3.7vh auto;
        line-height: 1.8;
    }
}

.Description {
    white-space: pre-line;
    color: var(--color-bright-text);
    text-align: center;
    margin-top: 16px;
}

.ButtonDecline {
    color: var(--color-bright-secondary-text);

    @include media_tablet {
        margin-top: 20px;
    }

    @include media_desktop_4k {
        height: 4.4vh;
    }
}

.ButtonArrow svg {
    fill: var(--color-button-dark);
}

.ButtonAgree {
    background-color: var(--color-black-a30);
    color: var(--color-button-text);

    @include media_desktop {
        height: 48px;
    }

    @include media_desktop_4k {
        height: 4.4vh;
    }
}

.Footer {
    margin-top: 30px;
}

.DescriptionContainer {
    text-align: justify;
    word-break: break-word;

    span {
        color: inherit !important;
    }

    a {
        background-image: none !important;
    }
}

.HeaderImage {
    height: 60px;
    width: 50px;

    path {
        stroke: var(--stroke-tnc-icon);
    }

    @include media_tablet {
        height: 75px;
        width: 63px;
    }

    @include media_desktop {
        width: 7.68vh;
        height: 9.26vh;
    }
}

.PopupContent {
    padding: 30px 27px 20px 28px;

    @include media_tablet {
        display: inline-table;
        background-color: var(--color-popup-background);
        margin: 63px 0;
        padding: 60px 9.5%;

        h2 {
            text-align: left;
        }

        h2::after {
            content: '';
            margin: 40px 0;
            width: 135px;
            height: 1px;
            display: block;
            clear: both;
            background-color: var(--color-rodeo-dust);
        }
    }

    @include media_desktop {
        margin: 8.05vh 11%;
        padding: 5.36vh 8%;
    }
}

.Popup {
    position: relative;
    background-color: var(--color-prefecence-center-background);
}
