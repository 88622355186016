@import '../../../assets/scss/medias';

.Wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.NotificationsContainer {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 12px 0 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 200;
}

.NotificationList {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    transform: translate(-50%, 0);

    @include media_tablet {
        max-width: 500px;
        top: 63px;
    }

    @include media_desktop {
        top: 0;
    }

    @include media_desktop_4k {
        max-width: none;
    }
}

@include media_desktop {
    .NotificationList {
        top: 90px;
    }
}
