.TncLink {
    font-family: var(--font-bold);
    cursor: pointer;
    text-decoration: underline;
}

.BrightTncLink {
    color: var(--color-bright-link) !important;
}

.DarkTncLink {
    color: var(--color-tuna) !important;
}
