@import '../../../assets/scss/medias';

.SwitchToggle {
    position: relative;
    width: 50px;
    height: 26px;
    padding: 3px;
    background-color: var(--color-alabaster-lighten);
    border-radius: 13px;
    cursor: pointer;
    user-select: none;
    z-index: 2;

    @include media_tablet {
        width: 74px;
        height: 39px;
        border-radius: 30px;
    }
}

.Toggle {
    width: 20px;
    height: 20px;
    background-color: var(--color-oslo-gray);
    border-radius: 50%;
    transition: 0.2s ease;
    transform: translateX(0);

    @include media_tablet {
        width: 30px;
        height: 30px;
        margin-top: 1px;
    }
}

.ToggleActive {
    position: relative;
    background-color: var(--color-limeade);
    transition: 0.2s ease;
    transform: translateX(24px);
    filter: var(--filter-grayscale);

    @include media_tablet {
        transform: translateX(38px);
    }
}

.ToggleActive::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '\2714';
    color: var(--color-white);
    font-size: var(--font-size__4-2_93);

    @include media_tablet {
        font-size: 2vh;
    }
}
