@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.PurchaseLaterPopup {
    padding: 30px 28px;

    @include media_tablet {
        padding: px-to-vh-tablet(60px) px-to-vw-tablet(62px) px-to-vh-tablet(40px);
    }

    @include media_desktop {
        padding: px-to-vh(90px) px-to-vh(64px) px-to-vh(60px);
    }

    .PurchaseLaterTitle {
        margin: 0 0 10px 0;
        word-break: break-word;
        text-align: center;

        @include media_tablet {
            letter-spacing: -2px;
            margin: 0 0 px-to-vh-tablet(24px) 0;
        }

        @include media_desktop {
            margin: 0 0 px-to-vh(40px) 0;
            width: auto;
        }
    }

    .PurchaseLaterDescription {
        text-align: center;
        font-size: var(--font-size__1-14);
        color: var(--color-txt-regular-text);
        white-space: pre-line;
        margin: 0 auto;
        width: auto;

        @include media_tablet {
            font-size: var(--font-size__3-16);
        }

        @include media_desktop {
            font-size: var(--font-size__3-20);
            width: px-to-vh(700px);
        }
    }

    .PurchaseLaterButton {
        margin-top: 30px;

        @include media_tablet {
            margin-top: px-to-vh-tablet(30px);
        }

        @include media_desktop {
            margin-top: px-to-vh(40px);
        }
    }
}
