@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.App {
    color: var(--color-dark-text);
    display: flex;
    font-family: var(--font-regular);
    justify-content: center;
    min-height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-dark-background);
}

.ComponentWrapper {
    display: flex;
    justify-content: center;
    min-height: 568px;
    width: 100%;

    @include media_desktop {
        min-height: px-to-vh(900px);
    }
}

@media only screen and (max-width: 420px) {
    .AppContainer {
        max-width: none;
    }
}
