@import '../../../../assets/scss/medias';

.Title {
    margin-bottom: 4px;

    @include media_desktop {
        margin-top: 2px;
        margin-bottom: 28px;
    }
}

.Description {
    text-align: center;
    margin-bottom: 30px;
    white-space: break-spaces;

    @include media_desktop {
        margin-top: 3.7vh;
    }
}
