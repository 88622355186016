@import 'medias';
@import 'helpers';

@mixin txt--regular {
    font-size: var(--font-size__2-14);
    color: var(--color-txt-regular-text);
    line-height: 1.86;

    @include media_tablet {
        font-size: var(--font-size__1-16);
        line-height: 28px;
    }

    @include media_desktop {
        font-size: var(--font-size__3-20);
        line-height: px-to-vh(36px);
    }

    &-fixed {
        font-size: var(--font-size__1-14);

        @include media_desktop {
            font-size: var(--font-size__3-20);
        }
    }
}

@mixin font-medium-style {
    :global(.ica-font-medium) {
        @content;
    }
}

@mixin font-big-style {
    :global(.ica-font-big) {
        @content;
    }
}

@mixin absolut-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
