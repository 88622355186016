@import '../../../../assets/scss/medias';

.Wrapper {
    position: fixed;
    right: 20%;
    z-index: 1000;

    @include media_tablet {
        transform: scale(0.75);
        top: 12px;
    }

    @include media_desktop {
        transform: scale(1);
        top: 40px;
    }
}

.Wrapper li,
.Wrapper ul {
    display: inline-flex;
}

.Wrapper ul {
    margin-right: 10px;
}

.ToolButton {
    cursor: pointer;
    background-color: white;
    border: 1px var(--color-dusty-gray) solid;
    margin-right: 1px;
    padding: 0;
}

.TextSizeButtonActive,
.ColorButtonActive {
    background-color: var(--color-dove-gray);
}

.TextSizeButtonActive svg {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.FontSizeList li:first-child svg {
    transform: scale(0.5);
}

.FontSizeList li:nth-child(2) svg {
    transform: scale(0.75);
}
