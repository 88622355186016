@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.IconTick.IconTick {
    filter: var(--filter-invert);
    transform: scale(var(--square-elements-scale));
    min-width: 16px;

    @include media_desktop {
        min-width: 1.75vh;
        min-height: 1.75vh;
    }
}
