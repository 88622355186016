@import '../../scss/helpers';

#root {
    --font-bold: NotoSans-Bold;
    --font-light: NotoSans-Light;
    --font-medium: NotoSans-Medium;
    --font-regular: NotoSans-Regular;

    --font-size__1-10: 10px;
    --font-size__1-11: 11px;
    --font-size__1-12: 12px;
    --font-size__1-14: 14px;
    --font-size__1-16: 16px;
    --font-size__1-18: 18px;
    --font-size__1-20: 20px;
    --font-size__1-24: 24px;
    --font-size__1-32: 32px;
    --font-size__1-40: 40px;

    --font-size__2-11: #{px-to-vw(11px)};
    --font-size__2-12: #{px-to-vw(12px)};
    --font-size__2-13: #{px-to-vw(13px)};
    --font-size__2-14: #{px-to-vw(14px)};
    --font-size__2-16: #{px-to-vw(16px)};
    --font-size__2-24: #{px-to-vw(24px)};

    --font-size__3-12: #{px-to-vh(12px)};
    --font-size__3-14: #{px-to-vh(14px)};
    --font-size__3-15: #{px-to-vh(15px)};
    --font-size__3-16: #{px-to-vh(16px)};
    --font-size__3-20: #{px-to-vh(20px)};
    --font-size__3-50: #{px-to-vh(50px)};

    --font-size__4-2_67: 2.67vw;
    --font-size__4-2_93: 2.93vw;
    --font-size__4-4: 4vw;
    --font-size__4-4_3: 4.3vw;
    --font-size__4-5_33: 5.33vw;
    --font-size__4-6_4: 6.4vw;

    --font-size__5-1_48: 1.48vh;
    --font-size__5-1_65: 1.65vh;
    --font-size__5-1_85: 1.85vh;
    --font-size__5-4_63: 4.63vh;

    //origin size * 135%
    &.ica-font-medium {
        --font-size__1-10: 13.5px;
        --font-size__1-11: 15px;
        --font-size__1-12: 16px;
        --font-size__1-14: 19px;
        --font-size__1-16: 21.5px;
        --font-size__1-18: 24px;
        --font-size__1-20: 27px;
        --font-size__1-24: 32px;
        --font-size__1-32: 43px;
        --font-size__1-40: 54px;

        --font-size__2-11: #{px-to-vw(15px)};
        --font-size__2-12: #{px-to-vw(16px)};
        --font-size__2-13: #{px-to-vw(17.5px)};
        --font-size__2-14: #{px-to-vw(19px)};
        --font-size__2-16: #{px-to-vw(22px)};
        --font-size__2-24: #{px-to-vw(32px)};

        --font-size__3-12: #{px-to-vh(16px)};
        --font-size__3-14: #{px-to-vh(19px)};
        --font-size__3-15: #{px-to-vh(20px)};
        --font-size__3-16: #{px-to-vh(21.5px)};
        --font-size__3-20: #{px-to-vh(27px)};
        --font-size__3-50: #{px-to-vh(67.5px)};

        --font-size__4-2_67: 3.6vw;
        --font-size__4-2_93: 3.95vw;
        --font-size__4-4: 5.4vw;
        --font-size__4-4_3: 5.8vw;
        --font-size__4-5_33: 7.2vw;
        --font-size__4-6_4: 8.64vw;

        --font-size__5-1_48: 2vh;
        --font-size__5-1_65: 2.23vh;
        --font-size__5-1_85: 2.5vh;
        --font-size__5-4_63: 6.25vh;
    }

    //origin size * 150%
    &.ica-font-big {
        --font-size__1-10: 15px;
        --font-size__1-11: 16.5px;
        --font-size__1-12: 18px;
        --font-size__1-14: 21px;
        --font-size__1-16: 24px;
        --font-size__1-18: 27px;
        --font-size__1-20: 30px;
        --font-size__1-24: 36px;
        --font-size__1-32: 48px;
        --font-size__1-40: 60px;

        --font-size__2-11: #{px-to-vw(16.5px)};
        --font-size__2-12: #{px-to-vw(18px)};
        --font-size__2-13: #{px-to-vw(19.5px)};
        --font-size__2-14: #{px-to-vw(21px)};
        --font-size__2-16: #{px-to-vw(24px)};
        --font-size__2-24: #{px-to-vw(36px)};

        --font-size__3-12: #{px-to-vh(18px)};
        --font-size__3-14: #{px-to-vh(21px)};
        --font-size__3-15: #{px-to-vh(22.5px)};
        --font-size__3-16: #{px-to-vh(24px)};
        --font-size__3-20: #{px-to-vh(30px)};
        --font-size__3-50: #{px-to-vh(75px)};

        --font-size__4-2_67: 4vw;
        --font-size__4-2_93: 4.4vw;
        --font-size__4-4: 6vw;
        --font-size__4-4_3: 6.45vw;
        --font-size__4-5_33: 8vw;
        --font-size__4-6_4: 9.6vw;

        --font-size__5-1_48: 2.22vh;
        --font-size__5-1_65: 2.47vh;
        --font-size__5-1_85: 2.77vh;
        --font-size__5-4_63: 6.94vh;
    }
}
