$tablet-width: 420px;
$desktop-width: 1025px;
$desktop_4k-width: 3840px;

@mixin media_tablet {
    @media only screen and (min-width: $tablet-width) {
        @content;
    }
}

@mixin media_desktop {
    @media only screen and (min-width: $desktop-width) {
        @content;
    }
}

@mixin media_desktop_4k {
    @media only screen and (min-width: $desktop_4k-width) {
        @content;
    }
}

@mixin media_custom($min-width, $max-width) {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}

@mixin media_landscape {
    @media (orientation: landscape) {
        @content;
    }
}
