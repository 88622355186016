@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';
@import '../../../../assets/scss/mixins';

.AppLoaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    box-sizing: border-box;
}

.AppLoader {
    position: relative;
    width: 64px;
    height: 64px;

    @include media_desktop {
        height: px-to-vh(100px);
        min-height: 80px;
        min-width: 80px;
        width: px-to-vh(100px);
    }

    div {
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 4px;
        border: 4px solid var(--color-white);
        border-radius: 50%;
        animation: appLoaderAnim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--color-white) transparent transparent transparent;

        @include media_desktop {
            height: px-to-vh(100px);
            min-height: 80px;
            min-width: 80px;
            width: px-to-vh(100px);
            border-width: 8px;
        }
    }
}

.LoaderMessage {
    margin-top: 30px;
    font-size: var(--font-size__4-5_33);
    font-family: var(--font-light);
    color: var(--color-white);
    text-align: center;

    @include media_desktop {
        margin-top: 40px;
    }
}

.TextMain {
    margin: 0;
    font-size: var(--font-size__2-24);
    line-height: px-to-vw(32px);
    letter-spacing: 0;
    color: var(--color-bright-secondary-text) !important;

    @include media_tablet {
        font-size: var(--font-size__1-40);
        line-height: px-to-vh(64px);
        letter-spacing: -2px;
    }

    @include media_desktop {
        font-size: var(--font-size__3-50);
    }
}

.TextSecondary {
    margin: 16px 0 0;
    font-size: var(--font-size__2-14);
    line-height: px-to-vw(26px);
    letter-spacing: 0;
    color: var(--color-bright-secondary-text) !important;

    @include media_tablet {
        font-size: var(--font-size__1-18);
        line-height: px-to-vh(36px);
    }

    @include media_desktop {
        font-size: var(--font-size__3-20);
    }
}

.AppLoader div:nth-child(1) {
    animation-delay: -0.45s;
}

.AppLoader div:nth-child(2) {
    animation-delay: -0.3s;
}

.AppLoader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes appLoaderAnim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 110;
    background-color: var(--color-charade-a60);

    @include media_tablet {
        position: absolute;
    }
}

.Opaque {
    background-color: var(--color-charade);
}

.LoaderCentered {
    @include absolut-centered();
}
