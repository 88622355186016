@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;

    @include media_tablet {
        width: px-to-vw-tablet(624px);
    }

    @include media_desktop {
        width: 100%;
        max-width: 1170px;
    }

    @include media_desktop_4k {
        max-width: 2420px;
    }
}

.Float {
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 10;

    @include media_tablet {
        left: 50%;
        transform: translateX(-50%);
    }
}
