@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/helpers';

.Title {
    margin: 0 auto;
    line-height: 32px;
    width: 315px;

    @include media_tablet {
        width: 100%;
    }
}

.ChoicesContainer {
    --choice-item-size: 0px;
    width: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;

    .ChoiceItemContainer {
        display: flex;
        flex-direction: column;

        p {
            margin: 30px auto 0;
            text-align: center;
            font-size: var(--font-size__3-20);
            font-family: var(--font-medium);
        }
    }

    .ChoiceItem {
        --choice-item-size: 131px;
        display: flex;
        flex-direction: column;
        margin: 0 19px;
        letter-spacing: 0;
        padding: 0;
        line-height: 26px;
        text-transform: none;
        overflow: initial;
        width: var(--choice-item-size);
        height: var(--choice-item-size);

        @include media_tablet {
            margin: 0 20px;
            line-height: 28px;
            --choice-item-size: 20.83333vw;
        }

        @include media_desktop {
            margin: 0 30px;
            line-height: 36px;
            --choice-item-size: 20.37037vh;
        }

        .RoundConfirmMark {
            display: none;

            .SvgContainer {
                width: px-to-vw(15px);
                height: px-to-vw(15px);
                overflow: visible;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                @include media_tablet {
                    width: px-to-vh-tablet(15px);
                    height: px-to-vh-tablet(15px);
                }

                @include media_desktop {
                    width: px-to-vh(19px);
                    height: px-to-vh(19px);
                }

                g {
                    stroke: var(--color-bright-confirm-mark);
                }
            }
        }
    }

    .RoundConfirmMark,
    .ChoiceItem:focus {
        box-shadow: 0 0 0 3px var(--color-dark-text);
        border-radius: 50%;
        box-sizing: border-box;

        .RoundConfirmMark {
            --round-position: calc(var(--choice-item-size) / 2);
            box-shadow: none;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 26px;
            height: 26px;
            text-align: center;
            margin: -45%;
            background-color: var(--color-dark-text);
            transform: rotate(-45deg) translate3d(var(--round-position), var(--round-position), 0) rotate(45deg);

            @include media_tablet {
                width: px-to-vw-tablet(30px);
                height: px-to-vw-tablet(30px);
            }

            @include media_desktop {
                width: px-to-vh(40px);
                height: px-to-vh(40px);
            }
        }
    }
}

.Image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
}
