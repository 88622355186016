@import '../../assets/scss/medias';
@import '../../assets/scss/helpers';
@import '../../assets/scss/mixins';

.ica {
    h1,
    h2 {
        font-size: var(--font-size__4-6_4);
        font-family: var(--font-regular);
        font-weight: normal;
        line-height: 1.33;
        color: var(--color-dark-text);

        @include media_tablet {
            font-size: var(--font-size__1-32);
            letter-spacing: -2px;
        }

        @include media_desktop {
            font-size: var(--font-size__3-50);
            line-height: 1.28;
            letter-spacing: -2px;
        }

        &.ls--0 {
            @include media_desktop {
                letter-spacing: 0;
            }
        }
    }

    h2 {
        color: var(--color-h2-dark-text);
        text-align: center;
    }

    h3 {
        font-size: var(--font-size__4-6_4);
        font-weight: normal;

        @include media_tablet {
            font-size: var(--font-size__1-32);
        }

        @include media_desktop {
            font-size: var(--font-size__3-50);
        }
    }

    h4 {
        font-size: var(--font-size__4-4_3);
        line-height: 1.5;
        text-align: center;
        letter-spacing: 5.6px;
        font-family: var(--font-light);
        color: var(--color-charade);
    }

    h5 {
        color: var(--color-charade);
        font-family: var(--font-medium);
        font-size: var(--font-size__4-5_33);
        font-weight: normal;
    }

    b {
        font-family: var(--font-bold);
    }

    img,
    svg {
        filter: var(--filter-grayscale);
    }

    video {
        filter: var(--filter-video);
    }
}

.ica--txt-medium {
    font-size: var(--font-size__2-11);
    color: var(--color-charade);
    letter-spacing: 2px;

    @include media_desktop {
        font-size: var(--font-size__3-16);
    }
}

.ica--txt-medium-bold {
    font-size: var(--font-size__2-11);
    color: var(--color-charade);
    letter-spacing: 2px;
    font-weight: bold;

    @include media_tablet {
        letter-spacing: 3px;
        font-size: var(--font-size__1-12);
    }

    @include media_desktop {
        font-size: var(--font-size__3-16);
    }
}

.ica--txt-regular {
    @include txt--regular();
}

.ica--txt-medium-light {
    color: var(--color-dark-text);
    font-family: var(--font-medium);
    font-size: var(--font-size__2-12);
    letter-spacing: 3px;

    @include media_tablet {
        font-size: var(--font-size__1-14);
    }

    @include media_desktop {
        letter-spacing: 4px;
        font-size: var(--font-size__3-16);
        line-height: normal;
    }
}

.txt--11 {
    font-size: var(--font-size__2-11);
    color: var(--color-txt-regular-text);
    line-height: 1.45;
    letter-spacing: 2px;

    @include media_tablet {
        font-size: var(--font-size__1-16);
        line-height: normal;
        letter-spacing: normal;
    }

    @include media_desktop {
        font-size: var(--font-size__3-20);
    }
}
