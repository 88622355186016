@import '../../../../../assets/scss/medias';

.CloseButton {
    top: 40px;
    right: 40px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: none;
    transform: scale(var(--square-elements-scale));

    @include media_tablet {
        display: block;
        top: 25px;
        right: 29px;
    }

    @include media_desktop {
        top: 36px;
        right: 36px;
        height: 40px;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: var(--color-back-button-text);
        transform-origin: center;
        transform: rotate(45deg);
        top: 0;
        left: 50%;
    }

    &:after {
        transform: rotate(-45deg);
    }
}
