@import '../../../../../../assets/scss/medias';
@import '../../../../../../assets/scss/helpers';

.CommonContainer {
    @include media_desktop {
        max-width: none;
        margin-top: px-to-vh(89px);
    }

    .ContinueButton {
        right: 0;
        left: 0;
        margin-top: 10px;

        @include media_tablet {
            position: relative;
            bottom: auto;
            margin-top: px-to-vh-tablet(35px);
        }
    }

    .NextButtonClassName {
        @include media_tablet {
            margin-top: px-to-vh-tablet(35px);
        }
    }
}
