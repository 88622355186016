@import '../../../assets/scss/medias';
@import '../../../assets/scss/helpers';

.NextBtn {
    margin: 45px 0;
}

.Dots {
    position: relative;
    margin-bottom: 60px;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    :global(li.slick-active button::before) {
        opacity: 0.75;
    }

    li {
        margin: 0 10px 0 0;
        width: auto;
        height: auto;
        padding: 0;
        cursor: pointer;
        display: inline-block !important; // RS: !important is used to override DCS styles

        @include media_tablet {
            margin-right: 16px;
        }

        @include media_desktop {
            margin-right: 14px;
        }

        button {
            width: auto;
            height: auto;
            padding: 0;
            font-size: 0;
            line-height: 0;
            display: block;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            position: relative;

            &:focus {
                outline: none !important;
            }

            &:before {
                content: '\2022';
                position: relative;
                opacity: 0.25;
                color: var(--color-carousel-dots);
                -webkit-font-smoothing: antialiased;
                font-size: 18px;
                font-family: Arial, Helvetica, sans-serif;

                @include media_tablet {
                    font-size: 20px;
                }

                @include media_desktop {
                    font-size: px-to-vh(22px);
                }
            }
        }
    }
}

.DotsFocus {
    @include media_desktop {
        button:hover::before,
        button:focus::before {
            opacity: 0.5;
        }
    }
}
