@import '../../assets/scss/medias';

.button-group {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.button-group button {
    width: 48%;
}

.icon--fail {
    width: 20px;
    height: 20px;
    background-color: var(--color-atlantis);
    border-radius: 50%;
    position: relative;
    filter: var(--filter-grayscale);
}

.icon--fail {
    background-color: var(--color-guardsman-red);
}

.icon--fail::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.icon--fail::after {
    content: '\0021';
    color: var(--color-white);
    font-size: var(--font-size__4-4_3);

    @include media_tablet {
        font-size: 20px;
    }
}

@include media_desktop {
    .icon--fail::after {
        font-size: 2.57731vh;
    }

    .icon--fail::after {
        left: 49%;
    }
}

.slick-slide {
    .ica--device-description {
        display: none;
    }

    &.slick-current {
        .ica--device-description {
            display: block;
        }
    }
}
